<template>
  <div>
    <b-card class="px-2 py-2">
      <b-row class="align-items-end mb-2">
        <b-col
          cols="4"
          class="mb-1 mb-sm-0">
          <SearchTextInput
            v-model="filter.searchCodeAndName"
            label="Search"
            placeholder="ช่องทางการแจ้งเรื่อง"
            @keyup.enter.native="fetchFilter()" />
        </b-col>
        <b-col
          cols="4"
          class="mb-1 mb-sm-0">
          <FilterDropdown
            v-model="filter.userTeamId"
            label="Team"
            placeholder="Team"
            :options="options.userTeam"
            label-option="name"
            return-value="id"
            @input="fetchFilter()" />
        </b-col>
        <b-col
          cols="4"
          class="mb-1 mb-sm-0">
          <FilterDropdown
            v-model="filter.userGroupId"
            label="Group"
            placeholder="Group"
            :options="options.userGroup"
            label-option="name"
            return-value="id"
            @input="fetchFilter()" />
        </b-col>
        <b-col
          class="mb-1 mb-sm-0"
          cols="12"
          md="4">
          <DatePickerFilter
            v-model="filter.startDate"
            label="Start Date" />
        </b-col>
        <b-col
          class="mb-1 mb-sm-0"
          cols="12"
          md="4">
          <DatePickerFilter
            v-model="filter.endDate"
            label="End Date" />
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="w-100 mt-2"
            variant="primary"
            @click="getReport()"
          >
            <span class="align-middle">Export</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'
import ReportProvider from '@/resources/ReportProvider'
import UserTeamProvider from '@/resources/UserTeamProvider'
import UserGroupProvider from '@/resources/UserGroupProvider'

const ReportService = new ReportProvider()
const UserTeamService = new UserTeamProvider()
const UserGroupService = new UserGroupProvider()

export default {
  components: {
    DatePickerFilter,
    SearchTextInput,
    FilterDropdown
  },
  data () {
    return {
      isDeleteModalActive: false,
      filter: {
        searchCodeAndName: '',
        userTeamId: '',
        userGroupId: '',
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'name', label: 'ช่องทางการแจ้งเรื่อง', sortable: false },
        { key: 'total', label: 'Total', sortable: false },
        { key: 'ticketStatus.closed', label: 'Closed', sortable: false },
        { key: 'ticketStatus.closedPercent', label: '%', sortable: false },
        { key: 'ticketStatus.open', label: 'In Progress', sortable: false },
        { key: 'ticketStatus.openPercent', label: '%', sortable: false },
        { key: 'ticketStatus.void', label: 'Cancel', sortable: false },
        { key: 'ticketStatus.voidPercent', label: '%', sortable: false },
        { key: 'sla.inSla', label: 'In SLA' },
        { key: 'sla.inSlaPercent', label: '%' },
        { key: 'sla.overSla', label: 'Over SLA' },
        { key: 'sla.overSlaPercent', label: '%' }
      ],
      items: [],
      selectedItem: {},
      initialTicketType: {},
      deleteItem: {},
      ticketType: [],
      startDate: '',
      endDate: '',
      options: {
        userTeam: [],
        userGroup: []
      }
    }
  },
  mounted () {
    this.getAllUserTeam()
    this.getAllUserGroup()
  },
  methods: {
    async getReport () {
      try {
        await ReportService.summaryByChannelPaginate(this.pagination.page, this.pagination.limit, this.filter)
      } catch (error) {
        console.error(error)
      }
    },
    async getAllUserTeam () {
      try {
        const { data } = await UserTeamService.findAllTeamName()
        if (data) {
          this.options.userTeam = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllUserGroup () {
      try {
        const { data } = await UserGroupService.findAllGroupCode()
        if (data) {
          this.options.userGroup = data
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
